import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"

const DyakuemoPage = () => (
  <Layout>
    <SEO
      title="Дякуємо - Новий Телеком"
      description="Ваше звернення успішно відправлено! Зазвичай наші фахівці зв'язуються протягом 15 хвилин. Інтернет-провайдер Київської області - Новий Телеком."
      image=""
      url="/dyakuemo/"
      lang="uk"
      noindex="noindex"
    />
    <div className="hero-background-light">
      <Container className="py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/" replace>
                Newtelecom
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Дякуємо!
            </li>
          </ol>
        </nav>
        <h1 className="text-uppercase">Дякуємо!</h1>
        <div className="hero-slogan mb-5">
          <p>
            Ваше звернення успішно відправлено!
            <br />
            Зазвичай ми відповідаємо протягом 15 хвилин.
          </p>
          <Button as={Link} to="/" variant="danger" size="lg" replace>
            На головну
          </Button>
        </div>
      </Container>
    </div>
    <section className="dyakuemo container py-5">
      <div className="pt-3 pb-2 h2">Що можна зробити далі?</div>
      <ol>
        <li>
          Звернутися до цілодобової технічної підтримки за телефоном +38(044)
          277-4-277.
        </li>
        <li>Описати проблему на електронну скриню support@newtele.com.ua</li>
        <li>
          Або перейти на{" "}
          <Link to="/" replace>
            головну сторінку
          </Link>
          .
        </li>
      </ol>
    </section>
  </Layout>
)

export default DyakuemoPage
